/* eslint-disable @typescript-eslint/naming-convention, no-underscore-dangle */
import { NgClass, NgFor, NgIf } from '@angular/common';
import { Component, HostListener, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { country } from '@features/country/data';
import { user } from '@features/user/data';
import { LangChangeEvent, TranslateModule, TranslateService } from '@ngx-translate/core';
// Ignoring the linting check because this comes from a Kotlin Library
// @ts-ignore
import { getCountryFromIsoCode3 } from '@taager-experience-shared/country-resolver';
// @ts-ignore
import * as variants from '@taager-experience-shared/variants';
import { ToastrService } from 'ngx-toastr';
import { combineLatest, forkJoin, lastValueFrom, of, Subscription } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { PreOrderStatuses } from 'src/app/core/domain/bulk-pre-order/pre-order-status.model';
import { BaseCategoryModel } from 'src/app/core/domain/commercial-categories.model';
import { MerchantStore } from 'src/app/core/domain/merchant-store.model';
import { Analytics } from 'src/app/core/domain/products/sku-analytics.model';
import { DetailedRunRate, DetailedStockRange } from 'src/app/core/domain/stock-availability.model';
import { WalletModel } from 'src/app/core/domain/wallet/wallet.model';
import { LogGTagEventUseCase } from 'src/app/core/usecases/analytics/log-gtag-event.usecase';
import { LogMixpanelEventUseCase } from 'src/app/core/usecases/analytics/log-mixpanel-event.usecase';
import { GetBulkPreOrderRequestsUseCase } from 'src/app/core/usecases/bulk-preorder/get-bulk-pre-order-requests.usecase';
import { AddToCartUseCase } from 'src/app/core/usecases/cart/add-to-cart.usecase';
import { GetCategoryHierarchyUseCase } from 'src/app/core/usecases/get-category-hierarchy-usecase';
import { GetCommercialCategoriesUseCase } from 'src/app/core/usecases/get-commercial-categories-usecase';
import { GetFeatureAttributeUsecase } from 'src/app/core/usecases/get-feature-attribute.usecase';
import { GetFeatureFlagUsecase } from 'src/app/core/usecases/get-feature-flag.usecase';
import { GetMarketClosingReasonUseCase } from 'src/app/core/usecases/market-availability/get-closing-reason.usecase';
import { AddProductToStoreUseCase } from 'src/app/core/usecases/merchant-store/add-product-to-store.usecase';
import { GetUserStoresUseCase } from 'src/app/core/usecases/merchant-store/get-user-stores-usecase';
import { ListenToProductsPageExperienceChangesUseCase } from 'src/app/core/usecases/products/listen-to-products-page-experience-change.usecase';
import { ShouldDisplayNewHomePageUseCase } from 'src/app/core/usecases/products/should-display-new-home-page.usecase';
import { GetStockAvailabilityUseCase } from 'src/app/core/usecases/stock-availability/get-stock-availability.usecase';
import { GetUserCountryUseCase } from 'src/app/core/usecases/user-location/get-user-country.usecase';
import { CheckUserFeatureExistsUseCase } from 'src/app/core/usecases/user/check-user-feature-exists.usecase';
import { GetWalletsUseCase } from 'src/app/core/usecases/wallet/get-wallets.usecase';
import {
  SORT_BY_INTRODUCED_AT,
  SORT_BY_ORDER_COUNT,
} from 'src/app/presentation/shared/constants/category-products';
import { CarouselInterface } from 'src/app/presentation/shared/interfaces/product.interafce';
import { VariantGroup } from 'src/app/presentation/shared/interfaces/variant';
import { CatalogService } from 'src/app/presentation/shared/services/catalog.service';
import { LocalStorageService } from 'src/app/presentation/shared/services/local-storage.service';
import { ProductService } from 'src/app/presentation/shared/services/product.service';
import { PreOrderRulesDialogComponent } from '../../pre-order-requests/pre-order-rules-dialog/pre-order-rules-dialog.component';
import { BreadcrumbsComponent } from '../../shared/components/breadcrumbs/breadcrumbs.component';
import { LoaderComponent } from '../../shared/components/loader/loader.component';
import { OrderComponent } from '../../shared/components/order/order.component';
import {
  CURRENCIES,
  FEATURE_FLAGS,
  ORDER_STATUSES,
  STOCK_AVAILABILITY_USER_FEATURE,
  UPSELLABLE_PRODUCTS_CATEGORY,
} from '../../shared/constants';
import { ENGLISH_LANGUAGE } from '../../shared/constants/country-language-codes-mapping.constants';
import {
  FEATURE_ENABLED_FOR_EVEN_IDS,
  FEATURE_ENABLED_FOR_EVERYONE,
  FEATURE_ENABLED_FOR_ODD_IDS,
} from '../../shared/constants/feature-attribute-states';
import {
  DISCOUNTED_AS_SECOND_PRODUCT,
  PREORDER_V3,
  PRICE_RANGE_ATTRIBUTE,
  SKU_ANALYTICS,
  STOCK_AVAILABILITY_FEATURE,
  WEB_PRODUCT_DETAILS_LIBRARY_REMOVAL,
  WEB_STORES,
} from '../../shared/constants/feature-flags';
import { CLICKS_PLACE_PREORDER_REQUEST_BUTTON } from '../../shared/constants/mixpanel';
import { HoverStyleDirective } from '../../shared/directives/hover-style.directive';
import { CommercialCategoryTreeNode } from '../../shared/interfaces/commercial-categories.interface';
import { FlashSalesService } from '../../shared/services/flash-sales.service';
import { ResponsiveService } from '../../shared/services/responsive.service';
import { SiteTranslateService } from '../../shared/services/translate.service';
import { cesLocationNotifierUtility } from '../../shared/utilities/ces-location-notifier.utility';
import { getSizedImage, ImageSize } from '../../shared/utilities/get-sized-image.utility';
import { BulkPreOrderV3Component } from '../bulk-pre-order-v3/bulk-pre-order-v3.component';
import { BulkPreOrderDialogComponent } from '../bulk-pre-order/bulk-pre-order-dialog/bulk-pre-order-dialog.component';
import { ProductsCarouselComponent } from '../products-v2/products-carousel/products-carousel.component';
import { ReviewProductDialogComponent } from '../review-product-dialog/review-product-dialog.component';
import { ProductDetailsPreviewComponent } from './product-details-preview/product-details-preview.component';

@Component({
  selector: 'app-product-details',
  templateUrl: './product-details.component.html',
  styleUrls: ['./product-details.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    BreadcrumbsComponent,
    NgFor,
    HoverStyleDirective,
    NgClass,
    ProductsCarouselComponent,
    LoaderComponent,
    RouterLink,
    OrderComponent,
    TranslateModule,
    ProductDetailsPreviewComponent,
  ],
})
export class ProductDetailsComponent implements OnInit {
  public variantGroup: VariantGroup;

  public hasUpSellableVariants = false;

  public productName: string;

  public product: any;

  public state = 'loading';

  public isOrderNow = false;

  public selectedProducts: any[] = [];

  public showNavigationArrows = false;

  public showVidNavigationArrows = false;

  public showLargeNavigationArrows = false;

  public productImgArr: string[] = [];

  public productImgIdx: number;

  public productVidArr: any[] = [];

  public productVidTypeArr: string[] = [];

  public variantPossibleAttributes: { color?: string; size?: string }[];

  public colorVariantArr: (string | undefined)[] = [];

  public colorNamesArray: string[] = [];

  public selectedColor: string;

  public sizeVariantArr: (string | undefined)[] = [];

  public selectedSize: string;

  public numberOfItems = 1;

  public productDetails: string;

  public productSpecification: any[] = [];

  public productHowToUse: string;

  public detailsSectionSelected = '';

  public bestSellerCategories: BaseCategoryModel[] = [];

  public bestSellerCarouselData: CarouselInterface;

  public upSellableVariantsCarouselData: CarouselInterface;

  public bestSellersLoading = true;

  public facebookShareLink: string;

  public whatsappShareLink: string;

  public categoryNavigationQueryParams: any;

  public isCataloged: boolean;

  public productInvalid = false;

  public isCatalogDataLoaded = false;

  public currency: string;

  public categoryHierarchy: CommercialCategoryTreeNode[];

  boldPattern = /^\*.+\* *$/;

  starRegex = /\*/gi;

  private variantId: string;

  bulkPreOrderButtonIsVisible = false;

  productIsOrderable = true;

  public flashSaleTitle: string;

  public upsellableProductsFlag = false;

  private _bulkPreorderDialogRef: any;

  private _wallet: WalletModel;

  public preorderV2FeatureEnabled: boolean;

  public userHasStockAvailability: boolean;

  public showStockAvailability = false;

  public stockAvailabilityStatus: DetailedStockRange;

  public stockAvailabilityRunRate: DetailedRunRate;

  public shouldShowDiscountAsSecondProduct = false;

  public isResizeImageEnabled = false;

  public isPriceRangeEnabled = false;

  public skuAnalyticsEnabled: boolean;

  public skuAnalytics: Analytics;

  public storeId: string;

  public storeProvider: string;

  public userHasStore = false;

  public productAddedToStore = false;

  public isNewProductDetailsEnabled = false;

  public isEnglishLanguage: boolean;

  private _languageChangeSubscription: Subscription;

  public isStoresEnabled = false;

  public isDiscoverabilityFunnelEnabled = false;

  private _cesLocationNotifierUtility = cesLocationNotifierUtility();

  public closingMessage: string;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private localStorageService: LocalStorageService,
    private productService: ProductService,
    private catalogService: CatalogService,
    private _addToCartUseCase: AddToCartUseCase,
    private toastr: ToastrService,
    private sanitizer: DomSanitizer,
    private _getCommercialCategoriesUseCase: GetCommercialCategoriesUseCase,
    private _getFeatureFlagUseCase: GetFeatureFlagUsecase,
    private dialog: MatDialog,
    private _responsiveService: ResponsiveService,
    private _getUserCountryUseCase: GetUserCountryUseCase,
    private _logGTagEventUseCase: LogGTagEventUseCase,
    private _getBulkPreOrderRequestsUseCase: GetBulkPreOrderRequestsUseCase,
    private _getFeatureAttributeUsecase: GetFeatureAttributeUsecase,
    private _flashSalesService: FlashSalesService,
    private _addProductToStoreUseCase: AddProductToStoreUseCase,
    private _getStockAvailabilityUseCase: GetStockAvailabilityUseCase,
    private _logMixpanelEventUseCase: LogMixpanelEventUseCase,
    private _translateService: TranslateService,
    private _getUserStoresUseCase: GetUserStoresUseCase,
    private _siteTranslateService: SiteTranslateService,
    private _getWalletsUseCase: GetWalletsUseCase,
    private _getCategoryHierarchyUseCase: GetCategoryHierarchyUseCase,
    private _shouldDisplayNewHomePageUseCase: ShouldDisplayNewHomePageUseCase,
    private _listenToProductsPageExperienceChanges: ListenToProductsPageExperienceChangesUseCase,
    private _checkUserFeatureExistsUseCase: CheckUserFeatureExistsUseCase,
    public _getMarketClosingReasonUseCase: GetMarketClosingReasonUseCase,
  ) {
    this.isEnglishLanguage = this._siteTranslateService.getCurrentLanguage() === ENGLISH_LANGUAGE;
    this._languageChangeSubscription = this._translateService.onLangChange.subscribe({
      next: (event: LangChangeEvent) => {
        this.isEnglishLanguage = event.lang === ENGLISH_LANGUAGE;
      },
    });
  }

  @HostListener('window:popstate', ['$event'])
  onPopState(): void {
    this.isOrderNow = false;
  }

  ngOnInit(): void {
    this.getBestSellerCommercialCategories();
    this._getFeatureAttributeUsecase.execute(WEB_PRODUCT_DETAILS_LIBRARY_REMOVAL).subscribe({
      next: (attribute) => {
        if (attribute) {
          attribute = JSON.parse(attribute);
          if (attribute.status) {
            this.isNewProductDetailsEnabled = true;
          }
        }
      },
    });
    this._getFeatureAttributeUsecase.execute(PRICE_RANGE_ATTRIBUTE).subscribe({
      next: (flag) => {
        flag = JSON.parse(flag);
        const status = flag?.status.toLowerCase();
        if (
          flag.country.includes(country.code) &&
          ((status === FEATURE_ENABLED_FOR_ODD_IDS.toLowerCase() && user.id % 2 !== 0) ||
            (status === FEATURE_ENABLED_FOR_EVEN_IDS.toLowerCase() && user.id % 2 === 0) ||
            status === FEATURE_ENABLED_FOR_EVERYONE.toLowerCase() ||
            flag.taagerIds.map(Number).includes(user.id))
        ) {
          this.isPriceRangeEnabled = true;
        } else {
          this.isPriceRangeEnabled = false;
        }
      },
    });

    this.route.params.subscribe({
      next: (params) => {
        this.variantId = params.id;
        forkJoin({
          isCataloged: this.catalogService
            .getProductIsCataloged(this.variantId)
            .pipe(catchError(() => of(undefined))),
          variantGroup: this.productService
            .getVariantGroupByVariantId(this.variantId)
            .pipe(catchError(() => of(undefined))),
        }).subscribe({
          next: ({ isCataloged, variantGroup }) => {
            if (variantGroup) {
              this.isCataloged = isCataloged;
              this.variantGroup = variantGroup;
              this.showStockAvailability = false;
              this.processProduct();
              this.getCatalogedProducts();
              this.getCategoryHierarchy();
              this.setPreorderableProductData();
            } else {
              this.productInvalid = true;
            }
            this.state = 'loaded';
          },
          error(err) {
            // TODO: Handle Error State
            this.state = 'loaded';
          },
        });
      },
    });

    this.facebookShareLink = `http://www.facebook.com/share.php?u=${window.location.href}`;
    this.whatsappShareLink = `https://api.whatsapp.com/send?text=Check out this product! ${window.location.href}`;
    this._getFeatureAttributeUsecase.execute(FEATURE_FLAGS.FLASH_SALE_TITLE).subscribe({
      next: (title) => {
        this.flashSaleTitle = title;
      },
    });
    this._checkStoresEnabled();
    this._cesLocationNotifierUtility({ action: 'write', location: 'productDetails' });
    this.checkUserEligibilityForDiscoverabilityFunnel();
    this._getMarketClosureMessage();
  }

  private _getMarketClosureMessage(): void {
    this._getMarketClosingReasonUseCase.execute('orders').subscribe((res) => {
      if (res) {
        this.closingMessage = res;
      }
    });
  }

  ngOnDestroy(): void {
    this._cesLocationNotifierUtility({ action: 'write', location: ' ' });
  }

  checkForStockAvailabilityEligibility(): void {
    this._getFeatureFlagUseCase.execute(STOCK_AVAILABILITY_FEATURE).subscribe({
      next: (flag) => {
        const selectedCountry = country.code.toLocaleLowerCase();
        this.userHasStockAvailability =
          flag &&
          this._checkUserFeatureExistsUseCase.execute(
            `${STOCK_AVAILABILITY_USER_FEATURE}${selectedCountry}`,
          );
        if (this.userHasStockAvailability) {
          this._getStockAvailabilityUseCase.execute(this.product.prodID).subscribe({
            next: (res) => {
              this.showStockAvailability = true;
              this.stockAvailabilityStatus = res.detailedStockRange;
              this.stockAvailabilityRunRate = res?.detailedRunRate!;
              this._logMixpanelEventUseCase.execute({
                eventName: 'view_stock_success',
                payload: { productId: this.product.prodID },
              });
            },
            error: (err) => {
              this._logMixpanelEventUseCase.execute({
                eventName: 'view_stock_error',
                payload: {
                  productId: this.product.prodID,
                  errors: err.error.errorCode,
                },
              });
            },
          });
        }
      },
    });
  }

  getIsCataloged(): void {
    this.catalogService.getProductIsCataloged(this.variantId).subscribe({
      next: (res) => {
        this.isCataloged = res;
      },
    });
  }

  public getUpsellableProductCategory(): void {
    this._getFeatureFlagUseCase.execute(FEATURE_FLAGS.UPSELLABLE_PRODUCTS_FLAG).subscribe({
      next: (flag) => {
        this.upsellableProductsFlag = flag;
        this.setupProductWithUpSellableVariants();
      },
    });
  }

  getBestSellerCommercialCategories(): void {
    this._getCommercialCategoriesUseCase.execute(country.code).subscribe({
      next: (commercialCategories) => {
        this.bestSellerCategories = commercialCategories.root.children
          .map((category) => category.value)
          .slice(0, 4);
        this.bestSellerCarouselData = {
          title: '',
          categoryName: this.bestSellerCategories[0].name.arabicName,
          sorting: SORT_BY_ORDER_COUNT,
        };
        this.bestSellersLoading = false;
      },
    });
  }

  setPreorderableProductData(): void {
    this._getFeatureFlagUseCase.execute(FEATURE_FLAGS.PRE_ORDER_V2).subscribe({
      next: (preorderV2Flag) => {
        this.preorderV2FeatureEnabled = preorderV2Flag;
        if (!preorderV2Flag) {
          if (
            this.variantGroup &&
            this.variantGroup.primaryVariant &&
            this.variantGroup.primaryVariant.isPreOrderable
          ) {
            this.productIsOrderable = false;
            combineLatest({
              bulkPreorderExpirementFeatureFlag: this._getFeatureFlagUseCase.execute(
                FEATURE_FLAGS.BULK_PRE_ORDERS_EXPERIMENT,
              ),
              userPreorderRequests: this._getBulkPreOrderRequestsUseCase.execute(),
              userWallets: this._getWalletsUseCase.execute(),
            }).subscribe({
              next: ({ bulkPreorderExpirementFeatureFlag, userPreorderRequests, userWallets }) => {
                this._wallet = userWallets.find(
                  (walletItem) => walletItem.currency === CURRENCIES.SAR,
                )!;
                this.bulkPreOrderButtonIsVisible = bulkPreorderExpirementFeatureFlag;
                this.localStorageService.setStorage('preorderRequests', userPreorderRequests);
                const currentProductInProgressPreorderRequests = userPreorderRequests.filter(
                  (request) =>
                    request.prodId === this.variantGroup.primaryVariant!.prodID &&
                    request.status === PreOrderStatuses.INPROGRESS,
                );
                this.productIsOrderable = currentProductInProgressPreorderRequests.length > 0;
              },
            });
          }
        }
      },
    });
  }

  setPreorderableProductDataForPreorderV2(): void {
    if (this.preorderV2FeatureEnabled) {
      this._getFeatureFlagUseCase.execute(FEATURE_FLAGS.BULK_PRE_ORDERS_EXPERIMENT).subscribe({
        next: (flag) => {
          if (flag) {
            this._getWalletsUseCase.execute().subscribe({
              next: (userWallets) => {
                this.bulkPreOrderButtonIsVisible = this.product.isPreOrderable;
                this._wallet = userWallets.find(
                  (walletItem) => walletItem.currency === CURRENCIES.SAR,
                )!;
              },
            });
          }
        },
      });
    }
  }

  processProduct(): void {
    this.initializeProduct();

    this.state = 'loading';

    this.productService.getProductById(this.variantId).subscribe({
      next: (res: any) => {
        this.state = 'loaded';
        this.product = res.data;
        this.setupProductAnalytics();
        this.checkForStockAvailabilityEligibility();
        this.product = this._flashSalesService.checkAllSaleStartAndExpiry(this.product, true);
        this._getFeatureFlagUseCase.execute(DISCOUNTED_AS_SECOND_PRODUCT).subscribe({
          next: (flag) => {
            this.shouldShowDiscountAsSecondProduct = flag;
          },
        });
        if (this.product.isExpired) {
          this.productInvalid = true;
          return;
        }
        this.productInvalid = false;

        this.resizeImages();

        this.setupProductVariants();

        this.getUpsellableProductCategory();

        this.formatSelectedProduct();

        this.formatProductDetails();

        this.setupGallery();

        this.selectInitialDetailsSection();

        this.setupNavigationArrowsVisibility();

        this.setupCategoryNavigationQueryParams();

        this._trackProductEvent('product_page_load');

        this.setProductCurrency();

        this.setPreorderableProductDataForPreorderV2();
      },
      error: (err) => {
        this.productInvalid = true;
        this.state = 'error';
      },
    });
  }

  getCategoryHierarchy(): void {
    this._getCommercialCategoriesUseCase.execute(country.code).subscribe({
      next: (tree) => {
        if (tree && this.variantGroup.commercialCategoryIds) {
          const data = {
            countryCode: country.code,
            categoryId: this.variantGroup.commercialCategoryIds[0],
          };
          this._getCategoryHierarchyUseCase.execute(data).subscribe((categoryHierarchy) => {
            if (categoryHierarchy.length) {
              this.categoryHierarchy = categoryHierarchy;
            }
          });
        }
      },
    });
  }

  initializeProduct(): void {
    this.productImgArr = [];
    this.productVidArr = [];
    this.productVidTypeArr = [];
    this.productDetails = '';
    this.productSpecification = [];
    this.productHowToUse = '';
    this.colorVariantArr = [];
    this.sizeVariantArr = [];
  }

  resizeImages(): void {
    this._getFeatureFlagUseCase.execute(FEATURE_FLAGS.RESIZE_IMAGES).subscribe({
      next: (flag) => {
        if (flag) {
          this._getFeatureAttributeUsecase
            .execute(FEATURE_FLAGS.ALLOWED_IDS_FOR_RESIZE_IMAGES)
            .subscribe({
              next: (ids) => {
                ids = JSON.parse(ids) || [];
                this.isResizeImageEnabled = ids?.length === 0 || ids.includes(user.id);
              },
            });
        }
      },
    });
  }

  setupGallery(): void {
    if (this.isResizeImageEnabled) {
      const resizedAdditionalMedia = this.product.additionalMedia.map((item: any) =>
        this.getMediaType(item) === 'img' ? getSizedImage(item, ImageSize.large) : item,
      );
      this.product = {
        ...this.product,
        productPicture: getSizedImage(this.product.productPicture, ImageSize.large),
        extraImage1:
          this.getMediaType(this.product.extraImage1) === 'img'
            ? getSizedImage(this.product.extraImage1, ImageSize.large)
            : this.product.extraImage1,
        extraImage2:
          this.getMediaType(this.product.extraImage2) === 'img'
            ? getSizedImage(this.product.extraImage2, ImageSize.large)
            : this.product.extraImage2,
        extraImage3:
          this.getMediaType(this.product.extraImage3) === 'img'
            ? getSizedImage(this.product.extraImage3, ImageSize.large)
            : this.product.extraImage3,
        extraImage4:
          this.getMediaType(this.product.extraImage4) === 'img'
            ? getSizedImage(this.product.extraImage4, ImageSize.large)
            : this.product.extraImage4,
        extraImage5:
          this.getMediaType(this.product.extraImage5) === 'img'
            ? getSizedImage(this.product.extraImage5, ImageSize.large)
            : this.product.extraImage5,
        extraImage6:
          this.getMediaType(this.product.extraImage6) === 'img'
            ? getSizedImage(this.product.extraImage6, ImageSize.large)
            : this.product.extraImage6,
        additionalMedia: resizedAdditionalMedia,
      };
    }
    // Adding Product Picture
    this.addMedia(this.product.productPicture);

    // Adding Extra Image if exists
    for (const key in this.product) {
      if (key.includes('extraImage') && this.product[key]) {
        this.addMedia(this.product[key]);
      }
    }

    // Adding Additional Media if exists
    if (this.product.additionalMedia) {
      for (const mediaItem of this.product.additionalMedia) {
        this.addMedia(mediaItem);
      }
    }

    // Adding embedded youtube videos
    if (this.product.embeddedVideos) {
      for (const embeddedVideo of this.product.embeddedVideos) {
        this.addMedia(embeddedVideo);
      }
    }

    this.productImgIdx = 0;
  }

  addMedia(mediaItem: any): void {
    const mediaType = this.getMediaType(mediaItem);
    if (mediaType === 'youtube') {
      this.productVidArr.push(this.sanitizer.bypassSecurityTrustResourceUrl(mediaItem));
      this.productVidTypeArr.push(mediaType);
    } else if (mediaType === 'img') {
      this.productImgArr.push(mediaItem);
    } else {
      this.productVidArr.push(mediaItem);
      this.productVidTypeArr.push(mediaType);
    }
  }

  formatSelectedProduct(): void {
    this.selectedProducts = [this.product].map((product) => ({
      ...product,
      newPrice: product.productPrice,
      selected: true,
      id: product._id,
      pid: product.prodID,
      quantity: this.numberOfItems,
      productPicture: this.isResizeImageEnabled
        ? getSizedImage(product.productPicture, ImageSize.small)
        : product.productPicture,
    }));
  }

  formatProductDetails(): void {
    if (this.product.specifications) {
      this.productSpecification = this.product.specifications
        .replace(/•\s?/g, '')
        .split('\r\n')
        .filter((spec: any) => spec !== '');
      this.productDetails = this.product?.productDescription;
    } else if (this.product?.productDescription) {
      if (this.product?.productDescription.split('-').length > 1) {
        this.productSpecification = this.product?.productDescription.split('-');
      } else {
        this.productDetails = this.product?.productDescription;
      }
    }
    this.productSpecification = this.productSpecification.map((specification) => {
      if (this.boldPattern.test(specification)) {
        return { text: specification.replace(this.starRegex, ''), type: 'bold' };
      }
      return { text: specification, type: 'none' };
    });
    this.productHowToUse = this.product.howToUse;
  }

  selectInitialDetailsSection(): void {
    if (this.productDetails) {
      this.detailsSectionSelected = 'شرح المنتج';
    } else if (this.productSpecification.length !== 0) {
      this.detailsSectionSelected = 'تفاصيل المنتج';
    } else if (this.productHowToUse !== '') {
      this.detailsSectionSelected = 'كيفية الاستخدام';
    } else if (this.productVidTypeArr.length !== 0) {
      this.detailsSectionSelected = 'فيديوهات المنتج';
    }
  }

  setupNavigationArrowsVisibility(): void {
    if (this.productImgArr.length > 5) {
      this.showNavigationArrows = true;
    }
    if (this.productVidArr.length > 1) {
      this.showVidNavigationArrows = true;
    }
    if (this.productImgArr.length > 1) {
      this.showLargeNavigationArrows = true;
    }
  }

  getVariantIdBySelectedAttributtes() {
    const selectedAttributtes = {
      size: this.selectedSize,
      color: this.selectedColor,
    };
    const sameColorVariants = this.variantGroup.variants.filter((variant) => {
      const variantColorAttributes = variant.attributes.filter(
        (attribute) => attribute.type === 'color',
      );
      return (
        variantColorAttributes[0] && variantColorAttributes[0].value === selectedAttributtes.color
      );
    });
    const sameSizeVariants = this.variantGroup.variants.filter((variant) => {
      const variantSizeAttributes = variant.attributes.filter(
        (attribute) => attribute.type === 'size',
      );
      return (
        variantSizeAttributes[0] && variantSizeAttributes[0].value === selectedAttributtes.size
      );
    });
    if (sameColorVariants.length > 0 && selectedAttributtes.size) {
      const variantWithSameColorAndSize = sameColorVariants.filter(
        (variant) => variant.attributes[1].value === selectedAttributtes.size,
      )[0];
      if (variantWithSameColorAndSize) {
        return variantWithSameColorAndSize._id;
      }
      return sameColorVariants[0]._id;
    }
    if (sameSizeVariants.length > 0) {
      return sameSizeVariants[0]._id;
    }
    return sameColorVariants[0]._id;
  }

  goToVariantById(variantId: any): void {
    this.router.navigate(['/product-details', variantId]);
  }

  initializeAllPossibleVariantPairs(): void {
    this.variantPossibleAttributes = this.variantGroup.variants.map((variant) => {
      const filteredColor = variant.attributes.filter((set) => set.type === 'color')[0];
      const filteredSize = variant.attributes.filter((attribute) => attribute.type === 'size')[0];
      return {
        color: filteredColor && filteredColor.value,
        size: filteredSize && filteredSize.value,
      };
    });
  }

  resetSizes(): void {
    const colorPossibleAttributes = this.variantPossibleAttributes.filter(
      (pair) => pair.color === this.selectedColor,
    );
    if (colorPossibleAttributes.length > 0) {
      this.sizeVariantArr = colorPossibleAttributes
        .map((pair) => pair.size)
        .filter((_size, index, arr) => _size && arr.indexOf(_size) === index);
    } else {
      this.sizeVariantArr = this.variantPossibleAttributes
        .filter((pair) => pair.size === this.selectedSize)
        .map((pair) => pair.size)
        .filter((_size, index, arr) => _size && arr.indexOf(_size) === index);
    }
  }

  onColorVariantAttributeChange(color: string): void {
    this.selectedColor = color;
    this.goToVariantById(this.getVariantIdBySelectedAttributtes());
  }

  onSizeVariantAttributeChange(size: string): void {
    this.selectedSize = size;
    this.goToVariantById(this.getVariantIdBySelectedAttributtes());
  }

  setupProductVariants(): void {
    if (this.variantGroup.attributeSets.length > 0) {
      this.initializeAllPossibleVariantPairs();
      const uniqVariantColors = this.variantPossibleAttributes
        .map((attribute) => attribute.color)
        .filter((_color, index, arr) => _color && arr.indexOf(_color) === index);
      if (uniqVariantColors.length > 0) {
        this.colorVariantArr = uniqVariantColors;
        this.colorNamesArray = this.colorVariantArr.map(
          (colorHex) => variants.getColorByHexCode(colorHex!).arabicName,
        );
        const filteredColor = this.product.attributes.filter(
          (attribute: any) => attribute.type === 'color',
        )[0];
        this.selectedColor = filteredColor ? filteredColor.value : '';
      }
      this.resetSizes();
      const filteredSize = this.product.attributes.filter(
        (attribute: any) => attribute.type === 'size',
      )[0];
      this.selectedSize = filteredSize ? filteredSize.value : '';
    }
  }

  setupProductWithUpSellableVariants(): void {
    if (this.upsellableProductsFlag) {
      this.hasUpSellableVariants = false;
      const upSellableVariantIds = this.product.upSellableVariants?.map(
        (upSellableVariant: any) => upSellableVariant.sku,
      );
      if (upSellableVariantIds.length > 0) {
        this.hasUpSellableVariants = true;
        this.upSellableVariantsCarouselData = {
          title: UPSELLABLE_PRODUCTS_CATEGORY,
          sorting: SORT_BY_ORDER_COUNT,
          upSellableVariantId: this.product.prodID,
        };
      }
    }
  }

  setupCategoryNavigationQueryParams(): void {
    this.categoryNavigationQueryParams = {
      category: this.product.Category,
      currentPage: 1,
      items: 12,
      sorting: SORT_BY_INTRODUCED_AT,
    };
  }

  getImgSrc(idx: number): string {
    return this.productImgArr[idx];
  }

  getVidSrc(idx: number): string[] {
    return this.productVidArr[idx];
  }

  getMediaType(link: string): string {
    switch (link.substr(link.lastIndexOf('.') + 1).toLowerCase()) {
      case 'jpg':
      case 'png':
      case 'jpeg':
        return 'img';
      case 'mp4':
      case 'mov':
        return 'vid';
      default:
        return 'youtube';
    }
  }

  onClickRight(): void {
    const lastIdx = this.productImgArr.length - 1;
    if (this.productImgIdx < lastIdx) {
      this.productImgIdx++;
    }
  }

  onClickLeft(): void {
    if (this.productImgIdx > 0) {
      this.productImgIdx--;
    }
  }

  onSelectImage(idx: number): void {
    this.productImgIdx = idx;
  }

  onChangeNoOfItems(buttonPressed: string): void {
    if (buttonPressed === '+') {
      this.numberOfItems++;
    } else if (buttonPressed === '-') {
      if (this.numberOfItems > 1) {
        this.numberOfItems--;
      }
    }
  }

  addToCart(): void {
    this._addToCartUseCase
      .execute({ productId: this.product._id, quantity: this.numberOfItems })
      .subscribe({
        next: () => {
          this.toastr.success(
            this._translateService.instant('PRODUCTS_PAGE.PRODUCT_CARD.ADDED_TO_CART'),
          );
          this._logAddToCartEventToAnalytics();
          this._trackProductEvent('product_page_click_add_to_cart');
        },
      });
  }

  onAddProductToStore(): void {
    const merchantStore: MerchantStore = {
      storeId: this.storeId,
      provider: this.storeProvider,
    };
    this._logMixpanelEventUseCase.execute({
      eventName: 'add_to_store_clicked',
      payload: {
        ...this.product,
        productSourcePage: 'product details page',
        store: this.storeProvider,
      },
    });
    this._addProductToStoreUseCase
      .execute({ merchantStore, productId: this.product.prodID })
      .subscribe({
        next: () => {
          this._logMixpanelEventUseCase.execute({
            eventName: 'add_to_store_success',
            payload: {
              ...this.product,
              productSourcePage: 'product details page',
              store: this.storeProvider,
            },
          });
          this.productAddedToStore = true;
          this.toastr.success(
            this.storeProvider,
            this._translateService.instant('STORES.ADDED_SUCCESSFULLY'),
          );
        },
        error: (err) => {
          this._logMixpanelEventUseCase.execute({
            eventName: 'add_to_store_fail',
            payload: {
              ...this.product,
              productSourcePage: 'product details page',
              errorMessage: err.error.description,
              store: this.storeProvider,
            },
          });
          this.toastr.error(err.error.description);
        },
      });
  }

  public reviewProduct(): void {
    this.dialog.open(ReviewProductDialogComponent, {
      width: '760px',
      height: '60vh',
      direction: this.isEnglishLanguage ? 'ltr' : 'rtl',
      data: {
        productId: this.product.prodID,
      },
    });
  }

  private async _logAddToCartEventToAnalytics(): Promise<void> {
    const eventName = 'Product_page_add_to_cart';
    const payload = {
      'Taager ID': user.id,
      Platform: `Web-${this._responsiveService.returnDeviceCategory()}`,
      'User Location': await lastValueFrom(this._getUserCountryUseCase.execute()),
      Product: this.product.productName,
      categoryId: this.product.categoryId,
      'Taager Selling Price': this.product.productPrice,
    };

    this._logGTagEventUseCase.execute({ eventName, payload });
  }

  orderSubmitted(e: any): void {
    if (e.status) {
      this.isOrderNow = false;
      this.showStockAvailability = false;
      this.selectedProducts = [];
      this.processProduct();
      this.toastr.success(this._translateService.instant('CATALOG_PAGE.SUCCESS.ORDER_COMPLETION'));
      this._logEventToAnalytics(e.orderID, e.orderQuantity, e.orderPrice);
    } else {
      const errorToDisplay =
        ORDER_STATUSES.ORDER_ERRORS.find((error) => e?.errorMessage?.includes(error?.description))
          ?.translationKey || 'ERRORS.GENERIC_ERROR_MESSAGE';
      this.toastr.error(this._translateService.instant(errorToDisplay));
    }
    this.isOrderNow = false;
  }

  /**
   *
   * @param orderNumber
   *
   * The order number eg XXXXX/YYYY
   *
   * @param orderQuantity
   *
   * The number of order made
   *
   * @param orderPrice
   *
   * The price set by the merchant for the order or the default one that was set by taager
   *
   */
  private async _logEventToAnalytics(
    orderNumber: string,
    orderQuantity: number,
    orderPrice: number,
  ): Promise<void> {
    const { prodID, categoryId } = this.product;
    const merchantPrice = orderPrice * orderQuantity;
    const taagerPrice = this.product.productPrice * orderQuantity;
    const merchantProfit =
      (orderPrice - this.product.productPrice + this.product.productProfit) * orderQuantity;
    const eventName = 'Product_page_order_now';
    const payload = {
      'Taager ID': user.id,
      'Merchant order number': orderNumber,
      Platform: `Web-${this._responsiveService.returnDeviceCategory()}`,
      'User location': await lastValueFrom(this._getUserCountryUseCase.execute()),
      Product: prodID,
      categoryId,
      'Taager Selling Price': taagerPrice,
      'Merchant Selling Price': merchantPrice,
      'Merchant Profit': merchantProfit,
      Quantity: orderQuantity,
    };
    this._logGTagEventUseCase.execute({ eventName, payload });
  }

  orderNow(numberOfItems: any): void {
    this.product.quantity = numberOfItems;
    this.isOrderNow = true;
    this._trackProductEvent('product_page_click_order_now');
    window.scrollTo(0, 0);
  }

  public onDownloadedImages(): void {
    this._trackProductEvent('product_page_click_download_all_images');
  }

  public onDownloadedVideos(): void {
    this._trackProductEvent('product_page_click_download_all_videos');
  }

  public displayRules(): void {
    const productData = {
      productId: this.product.prodID,
      sellingPrice: this.product.productPrice,
    };
    this._logMixpanelEventUseCase.execute({
      eventName: CLICKS_PLACE_PREORDER_REQUEST_BUTTON,
      payload: productData,
    });
    const dialogRef = this.dialog.open(PreOrderRulesDialogComponent, {
      width: '905px',
      height: '700px',
      direction: this._siteTranslateService.currentDir,
    });
    dialogRef.afterClosed().subscribe({
      next: (status) => {
        if (status && status.accepted) {
          this.openBulkRequestDialog();
        }
      },
    });
  }

  public openBulkRequestDialog(): void {
    this._getFeatureFlagUseCase.execute(PREORDER_V3).subscribe((flag) => {
      if (flag) {
        this._bulkPreorderDialogRef = this.dialog.open(BulkPreOrderV3Component, {
          width: '905px',
          height: '700px',
          data: {
            walletAmount: this._wallet.eligibleAmount,
            sellingPrice: this.product.productPrice,
            merchantMargin: this.product.productProfit,
            productId: this.product.prodID,
          },
          direction: this._siteTranslateService.currentDir,
        });
      } else {
        this._bulkPreorderDialogRef = this.dialog.open(BulkPreOrderDialogComponent, {
          width: '905px',
          data: {
            walletAmount: this._wallet.eligibleAmount,
            sellingPrice: this.product.productPrice,
            merchantMargin: this.product.productProfit,
            productId: this.product.prodID,
          },
          direction: this._siteTranslateService.currentDir,
        });
      }
    });
  }

  reload(): void {
    this.isOrderNow = false;
    this.selectedProducts = [];
    this.processProduct();
  }

  onSelectDetailsSection(section: string): void {
    this.detailsSectionSelected = section;
  }

  onSelectBestSellerCategory(category: string): void {
    this.bestSellersLoading = true;
    this.bestSellerCarouselData.categoryName = category;
    setTimeout(() => {
      this.bestSellersLoading = false;
    }, 0);
  }

  onCatalogProduct(): void {
    if (this.isCataloged) {
      this._trackProductEvent('product_page_click_remove_from_catalog');
      this.catalogService.uncatalogProduct(this.variantId).subscribe({
        next: () => {
          this.getIsCataloged();
          this.toastr.success(
            this._translateService.instant('CATALOG_PAGE.SUCCESS.PRODUCT_REMOVED'),
          );
        },
        error: (err) => {
          this.toastr.error(this._translateService.instant('ERRORS.GENERIC_ERROR_MESSAGE'));
        },
      });
    } else {
      this._trackProductEvent('product_page_click_add_to_catalog');
      this.catalogService.catalogProduct(this.variantId).subscribe({
        next: () => {
          this._trackAddToCatalog();
          this.getIsCataloged();
          this.toastr.success(
            this._translateService.instant('PRODUCTS_PAGE.PRODUCT_ADDED_TO_CATALOG'),
          );
        },
        error: (err) => {
          this.toastr.error(this._translateService.instant('ERRORS.GENERIC_ERROR_MESSAGE'));
        },
      });
    }
  }

  private async _trackAddToCatalog(): Promise<void> {
    const eventName = 'Product_page_add_to_catalog';
    const payload = {
      'Taager ID': user.id,
      Platform: `Web-${this._responsiveService.returnDeviceCategory()}`,
      'User Location': await lastValueFrom(this._getUserCountryUseCase.execute()),
      Product: this.product.productName,
      categoryId: this.product.categoryId,
      'Taager Selling Price': this.product.productPrice,
    };
    this._logGTagEventUseCase.execute({ eventName, payload });
  }

  getCatalogedProducts(): void {
    this.catalogService.getCatalogedProducts().subscribe({
      next: () => {
        this.isCatalogDataLoaded = true;
      },
      error: () => {
        this.isCatalogDataLoaded = true;
      },
    });
  }

  setProductCurrency(): void {
    const productCountry = getCountryFromIsoCode3(
      this.product.country ? this.product.country : 'EGY',
    );
    this.currency = productCountry.currency.arabicName;
  }

  public onAdditionalInfoChange(tabName: string): void {
    if (tabName === 'product description') {
      this._trackProductEvent('product_description');
    } else if (tabName === 'product specification') {
      this._trackProductEvent('product_specification');
    } else if (tabName === 'product how to use') {
      this._trackProductEvent('product_howToUse');
    } else if (tabName === 'product videos') {
      this._trackProductEvent('product_videos');
    }
  }

  private _trackProductEvent(eventName: string): void {
    let eventPropertiesObject: any = {
      'Interface Version': 'v2',
      'Category Id': this.product.categoryId,
      'Category Name': this.product.Category,
      'Product Id': this.product.prodID,
      'Product Name': this.product.productName,
      'Product Price': this.product.productPrice,
      'Product Profit': this.product.productProfit,
      'Product Availability Status': this.product.productQuantityStatus,
      'Is Has Variants': this.product.attributes && this.product.attributes.length > 0,
      'Is Added To Catalog': this.isCataloged,
    };
    if (eventName !== 'product_page_load') {
      eventPropertiesObject = {
        ...eventPropertiesObject,
        'Is Variant Selected': this.product.prodID !== this.variantGroup.primaryVariant!.prodID,
      };
    }
    this._logMixpanelEventUseCase.execute({ eventName, payload: eventPropertiesObject });
  }

  setupProductAnalytics(): void {
    this._getFeatureFlagUseCase.execute(SKU_ANALYTICS).subscribe({
      next: (flag) => {
        if (flag && this.product.analytics.featureAvailable && !this.product.isPreOrderable) {
          this.skuAnalyticsEnabled = true;
          this.skuAnalytics = this.product.analytics;
          this._logMixpanelEventUseCase.execute({
            eventName: 'view_sku_performance_success',
            payload: {
              product_id: this.product.prodID,
              is_empty: !(
                this.product.analytics.confirmationRate && this.product.analytics.deliveryRate
              ),
            },
          });
        }
      },
    });
  }

  private _checkStoresEnabled(): void {
    this._getFeatureFlagUseCase.execute(WEB_STORES).subscribe({
      next: (flag) => {
        if (flag) {
          this.isStoresEnabled = true;
          this._fetchUserStores();
        }
      },
    });
  }

  private _fetchUserStores(): void {
    this._getUserStoresUseCase.execute().subscribe({
      next: (stores) => {
        const selectedCountry = country.code;
        const storePerMarket = stores.filter((store) => store.market === selectedCountry);
        if (storePerMarket.length > 0) {
          this.userHasStore = true;
          const { storeId, provider } = storePerMarket[0];
          this.storeId = storeId;
          this.storeProvider = provider;
        }
      },
    });
  }

  checkUserEligibilityForDiscoverabilityFunnel(): void {
    this.isDiscoverabilityFunnelEnabled = this._shouldDisplayNewHomePageUseCase.execute();
    this._listenToProductsPageExperienceChanges.execute().subscribe(() => {
      this.isDiscoverabilityFunnelEnabled = this._shouldDisplayNewHomePageUseCase.execute();
    });
  }
}
